@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");
body{
  overflow: hidden;
  font-family: 'Voltaire', sans-serif;
}
header {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}
header button{
  background-color: #1a948d;
  padding: 10px;
  color:#f7f7f7;
  font-weight: 900;
  border:none;
  font-family: 'Voltaire', sans-serif;
}
.btns{
  font-family: 'Voltaire', sans-serif;
}
header button:last-child{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
header button:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
h1 {
  font-size: 36px;
  letter-spacing: -2.5px;
  margin-left: 30px;
}

h3 {
  font-size: 16px;
  letter-spacing: -1.5px;
  margin-top: 5px;
  margin-left: 35px;
}

a {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
}

a:hover {
  color: rgb(255, 255, 255);
}

/* hardware accelatator class */
.trans3d {
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform-style: preserve-3d;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform-style: preserve-3d;
  -ms-transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  /*-webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility:hidden;
  backface-visibility:hidden;*/
}

#contentContainer {
  user-select: none;
  position: absolute;
  height: 100%;
  width: 100%;
}

#carouselContainer {
  position: absolute;
  left: 50%;
  top: 50%;
}
#carouselContainer img {
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
@keyframes rotate {
  0% {
           transform: rotate(0deg);
       }
       100% {
           transform: rotate(360deg);
       }
   }
   .out-top {
       animation: rotate 20s linear infinite;
       transform-origin: 13px 25px;
   }
   .in-top {
       animation: rotate 10s linear infinite;
       transform-origin: 13px 25px;
   }
   .out-bottom {
       animation: rotate 25s linear infinite;
       transform-origin: 84px 93px;
   }
   .in-bottom {
       animation: rotate 15s linear infinite;
       transform-origin: 84px 93px;
   }
.carouselItem {
  width: 320px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -160px;
  margin-top: -90px;
  visibility: hidden;
}

.carouselItemInner {
  width: 320px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  border: 10px solid rgba(255, 255, 255, 0.5);
  left: 50%;
  top: 50%;
  margin-left: -160px;
  margin-top: -90px;
  text-align: center;
  padding-top: 50px;
}/*# sourceMappingURL=index.css.map */

.arrow-left {
  cursor: pointer;
  z-index: 999999999;
  width: 0; 
  height: 0; 
  background-color: transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid aqua;
  z-index: 99999999;
  position: absolute;
  top:50%;
  left: 10%;
  transform: translateY(-50%);
}

.arrow-right {
  cursor: pointer;
  width: 0; 
  height: 0; 
  background-color: transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid aqua;
  z-index: 99999999;
  position: absolute;
  top:50%;
  right: 10%;
  transform: translateY(-50%);
}
body {
  background-color: #fee440;
}
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: -1;
}

@keyframes rotate {
  0% {
           transform: rotate(0deg);
       }
       100% {
           transform: rotate(360deg);
       }
   }
   .out-top {
       animation: rotate 20s linear infinite;
       transform-origin: 13px 25px;
   }
   .in-top {
       animation: rotate 10s linear infinite;
       transform-origin: 13px 25px;
   }
   .out-bottom {
       animation: rotate 25s linear infinite;
       transform-origin: 84px 93px;
   }
   .in-bottom {
       animation: rotate 15s linear infinite;
       transform-origin: 84px 93px;
   }